<script>
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
import FlagButton from '@/components/FlagButton.vue';
import axios from 'axios'
import { userService } from "@/helpers/user.service"
import {errorCatcher} from "@/helpers/error-catcher";
import parsePhoneNumber from 'libphonenumber-js'
import Swal from "sweetalert2";
import {logoHelper} from "@/helpers/logo-helper";
import InformationPanelRender from "@/components/account/information-panel-render.vue";
import {routerHelper} from "../../../helpers/router-helper";
import verifyChangePhoneNumber from "../../../components/verify-change-phone-number.vue";
import {timeUtil} from "../../../helpers/time-util";

export default {
  components: {InformationPanelRender, FlagButton, verifyChangePhoneNumber},
  data() {
    return {
      code: "",
      submitted: false,
      status: '',
      emailVerification: false,
      hasPhoneNumber: false,

      account: {
        dialCode: "",
        phoneNumber: "",
      }
    };
  },

  computed: {
    logoHelper() {
      return logoHelper
    },

    userService() {
      return userService
    },

    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    }
  },

  async created() {
    document.body.classList.add("auth-body-bg");

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());

    if (urlParams.identifier && urlParams.code) {
      await this.fetchStatus(urlParams.identifier)
      this.emailVerification = true;
      this.status = 'EMAIL';
      this.code = urlParams.code
      this.verify(urlParams.identifier)
      return;
    } else {
      await this.fetchStatus(null)
    }

    if (urlParams.code) {
      this.verifyAsEmail(urlParams.code);
      return;
    }

    this.resendCode(false)
  },

  validations: {
    code: {required, numeric, minLength: minLength(6), maxLength: maxLength(6) },
    account: {
      dialCode: { required },
      phoneNumber: { required },
    }
  },

  methods: {

    onSelectCountry({dialCode}) {
      this.account.dialCode = dialCode
    },

    isPossibleNumber() {
      if (!this.account.dialCode || !this.account.phoneNumber) {
        return false
      }

      const phoneNumber = parsePhoneNumber("+" + this.account.dialCode + this.account.phoneNumber)
      if (!phoneNumber) {
        return false
      }

      return phoneNumber.isValid()
    },

    updatePhoneNumber() {
      this.submitted = true

      this.$v.account.$touch();
      if (this.$v.account.$invalid) {
        return;
      }

      if (!this.isPossibleNumber()) {
        return
      }

      const json = JSON.stringify({
        "dialCode": "+" + this.account.dialCode,
        "phoneNumber": this.account.phoneNumber,
      })

      axios.post(`/user/update-phone-number`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.hasPhoneNumber = true
        Swal.fire("Sukces!", "Numer został ustawiony. Zweryfikuj swój numer telefonu.", "success");
        this.resendCode(false)
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    tryToVerify() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.code.$touch();

      if (!this.$v.code.$invalid) {
        const {code} = this;
        if (code) {
          this.verify();
        }
      }
    },

    verifyAsEmail(code) {
      this.emailVerification = true;
      this.number = 'EMAIL';
      this.code = code;
      this.verify();
    },

    async fetchStatus(identifier) {
      const params = identifier ? {"verificationUserId": identifier} : {};
      const requestUrl = identifier
          ? '/user/verification/verify-by-url'
          : '/user/verification/status'

      try {
        const {data} = await axios.get(`${requestUrl}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: params
        })

        this.status = data

        if (this.status === "PHONE_NUMBER") {
          await this.fetchHasSetPhoneNumber()
        }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async fetchHasSetPhoneNumber() {
      try {
        const {data} = await axios.get(`/user/is-set-phone-number`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.hasPhoneNumber = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    verify(identifier) {
      const json = identifier
          ? JSON.stringify({
            "verificationUserIdentifier": identifier,
            "code": this.code,
          }) : JSON.stringify({
            "code": this.code,
          });

      const requestUrl = identifier
          ? '/user/verification/verify-by-url'
          : '/user/verification/verify'

      axios.post(`${requestUrl}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.submitted = false;
        this.code = "";

        this.$bvToast.toast(this.$t("verify.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        const nextStep = result.data.nextStep;
        if (nextStep !== null && nextStep !== 'NONE') {
          this.emailVerification = false;

          if (nextStep === 'VERIFY_PHONE_NUMBER') {
            this.status = 'PHONE_NUMBER'
          } else if (nextStep === 'VERIFY_EMAIL') {
            this.status = 'EMAIL'
          } else {
            this.status = ''
          }

          this.resendCode(true)
          return;
        }

        userService.login(true);
      }).catch((error) => {
        this.submitted = false;
        this.code = "";

        if (error.response) {
          let json = JSON.parse(JSON.stringify(error.response.data));
          let errorCode = json.error.code;
          if (errorCode === 3) {
            userService.login(true);
            return
          }
        }

        if (!identifier) {
          errorCatcher.catchErrors(error)
        } else {
          routerHelper.push("/login")
        }
      })
    },

    resendCode(notification) {
      if (this.status && this.status === "NONE") {
        return
      }

      if (!this.hasPhoneNumber && this.status === 'PHONE_NUMBER') {
        return
      }

      if (notification) {
        const currentTime = new Date().getTime();
        if (this.status === "PHONE_NUMBER" && this.$store.getters["lastclick/getP"]) {
          if (this.$store.getters["lastclick/getP"] > currentTime) {
            Swal.fire(this.$t('message.error'), "Następny raz kod możesz wysłać za: " + timeUtil.toHumanReadableTime(this.$store.getters["lastclick/getP"] - currentTime), "error");
            return
          }
        } else if (this.status === "EMAIL" && this.$store.getters["lastclick/getE"]) {
          if (this.$store.getters["lastclick/getE"] > currentTime) {
            Swal.fire(this.$t('message.error'), "Następny raz kod możesz wysłać za: " + timeUtil.toHumanReadableTime(this.$store.getters["lastclick/getE"] - currentTime), "error");
            return
          }
        }
      }

      const json = JSON.stringify({
        type: this.status,
        environmentType: process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE
      });

      axios.post(`/user/verification/${notification ? 'send' : 'send-one'}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(async () => {
        if (notification) {
          this.$bvToast.toast(this.$t("verify.sent-code"), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });
        }

        if (notification) {
          const time = new Date(new Date().getTime() + (14 * 60 * 1000)).getTime()
          if (this.status === "PHONE_NUMBER") {
            await this.$store.dispatch("lastclick/setP", time)
          } else if (this.status === "EMAIL") {
            await this.$store.dispatch("lastclick/setE", time)
          }
        }

        this.submitted = false;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))" type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'" style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img :src="logoHelper.logo()" :height="getImageSize" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{  $t('landing-page-title') }}</h4>

                        <template v-if="emailVerification">
                          <p class="text-muted">{{ $t('verify.title.verification') }}</p>
                        </template>
                        <template v-else>
                          <template v-if="status === 'PHONE_NUMBER'">
                            <p class="text-muted">{{ $t('verify.title.number') }}</p>
                          </template>
                          <template v-else>
                            <template v-if="status === 'EMAIL'">
                              <p class="text-muted">{{ $t('verify.title.email') }}</p>
                            </template>
                          </template>
                        </template>
                      </div>

                      <template v-if="!emailVerification">
                        <template v-if="(hasPhoneNumber && status === 'PHONE_NUMBER') || status === 'EMAIL'">
                        <div class="p-2 mt-5">
                            <div class="form-horizontal">
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-mail-line auti-custom-input-icon"></i>
                                <label for="code">{{ $t('message.verification-code', { 'type': status === 'EMAIL' ? 'E-mail' : 'SMS' }) }}</label>
                                <input
                                    type="text"
                                    v-model="code"
                                    class="form-control"
                                    id="code"
                                    :placeholder="$t('message.type', { 'type': $t('message.verification-code', { 'type': status === 'EMAIL' ? 'E-mail' : 'SMS' }) })"
                                    :class="{ 'is-invalid': submitted && $v.code.$error }" />

                                <div v-if="submitted && $v.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.code.required">{{  $t('message.required') }}</span>
                                </div>

                                <div v-if="submitted && $v.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.code.numeric">{{  $t('message.is-number') }}</span>
                                </div>

                                <div v-if="submitted && $v.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.code.minLength">{{  $t('verify.error.length') }}</span>
                                </div>

                                <div v-if="submitted && $v.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.code.maxLength">{{  $t('verify.error.length') }}</span>
                                </div>
                              </div>

<!--                              <template v-if="status === 'PHONE_NUMBER'">-->
<!--                                <div class="row">-->
<!--                                  <div class="col-lg-3">-->
<!--                                    <div class="form-group auth-form-group-custom mb-2">-->
<!--                                      <vue-country-code @onSelect="onSelectCountry" :enabledFlags="true" :enabledCountryCode="true" :preferredCountries="['pl', 'us']" class="custom-select" :class="{ 'is-invalid': submitted && $v.account.dialCode.$error }" id="dialCode" style="height: 60px; width: 120px; background: none !important;"></vue-country-code>-->
<!--                                    </div>-->
<!--                                  </div>-->

<!--                                  <div class="col-lg-9">-->
<!--                                    <div class="form-group auth-form-group-custom mb-2">-->
<!--                                      <i class="ri-phone-line auti-custom-input-icon"></i>-->
<!--                                      <label for="phoneNumber">{{ $t('message.phone-number')}} </label>-->

<!--                                      <input-->
<!--                                          v-model="account.phoneNumber"-->
<!--                                          type="text"-->
<!--                                          class="form-control"-->
<!--                                          id="phoneNumber"-->
<!--                                          :placeholder="$t('message.type', { 'type': $t('message.phone-number').toLowerCase()})"-->
<!--                                          :class="{ 'is-invalid': (submitted && $v.account.phoneNumber.$error) || (submitted && !isPossibleNumber()) }"/>-->

<!--                                      <div v-if="submitted && !$v.account.phoneNumber.required" class="invalid-feedback">{{ $t('message.required')}}</div>-->
<!--                                      <div v-if="submitted && !isPossibleNumber()" class="invalid-feedback">{{ $t('message.number-error')}}</div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </template>-->

<!--                              <div class="mt-4 text-center" v-if="status === 'PHONE_NUMBER'">-->
<!--                                <button @click="updatePhoneNumber" class="btn btn-primary w-md waves-effect waves-light w-100">Ustaw numer telefonu</button>-->
<!--                              </div>-->

                              <div class="mt-4 text-center">
                                <div class="col-xl-12 row nopadding">
                                  <div class="col-xl-6 pl-0">
                                    <button @click="tryToVerify" class="btn btn-primary w-md waves-effect waves-light w-100">{{ $t('message.verify') }}</button>
                                  </div>
                                  <div class="col-xl-6 pr-0">
                                    <button @click="resendCode(true)" class="btn btn-outline w-md waves-effect waves-light w-100">{{ $t('verify.resend-code') }}</button>
                                  </div>
                                </div>
                              </div>

                              <div class="row mt-4">
                                <div class="col-12 col-lg-6">
                                  <div class="text-left">
                                    <a @click="$refs.verifyChangePhoneNumber.openModal" class="clickable-element font-weight-medium text-primary">Zmień numer telefonu</a>
                                  </div>
                                </div>

                                <div class="col-12 col-lg-6">
                                  <div class="text-right">
                                    <a @click="userService.logout" class="clickable-element font-weight-medium text-primary">{{ $t('navbar.dropdown.logout')}}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        </template>
                      </template>

                      <template v-if="!hasPhoneNumber && status === 'PHONE_NUMBER'">
                        <div class="row">
                          <div class="col-lg-3">
                            <div class="form-group auth-form-group-custom mb-2">
                              <vue-country-code @onSelect="onSelectCountry" :enabledFlags="true" :enabledCountryCode="true" :preferredCountries="['pl', 'us']" class="custom-select" :class="{ 'is-invalid': submitted && $v.account.dialCode.$error }" id="dialCode" style="height: 60px; width: 120px; background: none !important;"></vue-country-code>
                            </div>
                          </div>

                          <div class="col-lg-9">
                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-phone-line auti-custom-input-icon"></i>
                              <label for="phoneNumber">{{ $t('message.phone-number')}} </label>

                              <input
                                  v-model="account.phoneNumber"
                                  type="text"
                                  class="form-control"
                                  id="phoneNumber"
                                  :placeholder="$t('message.type', { 'type': $t('message.phone-number').toLowerCase()})"
                                  :class="{ 'is-invalid': (submitted && $v.account.phoneNumber.$error) || (submitted && !isPossibleNumber()) }"/>

                              <div v-if="submitted && !$v.account.phoneNumber.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                              <div v-if="submitted && !isPossibleNumber()" class="invalid-feedback">{{ $t('message.number-error')}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="mt-4 text-center">
                          <button @click="updatePhoneNumber" class="btn btn-primary w-md waves-effect waves-light w-100">Ustaw numer telefonu</button>
                        </div>

                        <div class="mt-4 text-center">
                          <a @click="userService.logout" class="clickable-element font-weight-medium text-primary">{{ $t('navbar.dropdown.logout')}}</a>
                        </div>
                      </template>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <information-panel-render/>
          </div>
        </div>
      </div>
    </div>

    <verify-change-phone-number ref="verifyChangePhoneNumber" />
  </div>
</template>